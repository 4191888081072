import React, { useState, useEffect } from 'react';
import {
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    SelectInput,
    FormDataConsumer,
    required
} from 'react-admin';

import { PlayAudioButton } from './PlayAudioButton';

const LexiconEdit = props => {
    const record = useRecordContext();
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        if (record && record.config) {
            const defaultLanguage = record.default_language;
            const additionalLanguages = record.additional_languages;
            setLanguages([defaultLanguage, ...additionalLanguages]);
        }
    }, [record]);

    return (
        <>
            {languages.map(language => (
                <div key={language}>
                    <h3>Pronunciation for {language}</h3>
                    <ArrayInput source={`lexicon.${language}`} label={false}>
                        <SimpleFormIterator inline disableReordering>
                            <TextInput 
                                source="original" 
                                label="Original"
                                validate={required()}
                            />
                            <SelectInput
                                source="type"
                                validate={required()}
                                defaultValue={'replacement'}
                                choices={[
                                    { id: 'replacement', name: 'Replace' },
                                    { id: 'phonetic', name: 'Phonetic' }
                                ]}
                            />
                            <TextInput 
                                source="replacement" 
                                label="Replacement"
                                validate={required()}
                            />
                            <FormDataConsumer>
                                {({ formData, scopedFormData, getSource, ...rest }) => (
                                    scopedFormData && (
                                        <PlayAudioButton
                                            endpoint="/assistants/play/audio" 
                                            params={{
                                                language: language,
                                                text: scopedFormData.original,
                                                type: scopedFormData.type,
                                                lexicon: formData.lexicon,
                                                id: record.id
                                            }}
                                        />
                                    )
                                )}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </div>
            ))}
        </>
    );
};

export default LexiconEdit;
