import * as React from 'react';
import { Button, FilterButton, useNotify, PrevNextButtons, DeleteButton, Loading, useGetManyAggregate, useRecordContext, usePermissions, FunctionField, useTranslate, useShowController, TopToolbar, SelectInput, ReferenceArrayInput, ListButton, Datagrid, DateField, List, SimpleList, ReferenceField, TextField, Show, SimpleShowLayout, TextInput, SelectArrayInput, AutocompleteArrayInput, DateInput, useGetOne } from 'react-admin';
import StructuredJsonField from './StructuredJsonField';
import DurationField from './DurationField';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PrevNextButtonProps, usePrevNextController } from 'react-admin';
import { useNavigate, Link, useParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MessageIcon from '@mui/icons-material/Message';
import NotesIcon from '@mui/icons-material/Notes';

import TagsManager, { allTags } from './TagsManager';
import { ConversationsConversationNotesList } from './ConversationNotes';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const tags = Object.keys(allTags).map(key => ({ id: key, name: key }));

const TagsManagerWrapper = ({ isEditable = false, label }: { isEditable?: boolean, label?: string }) => {
    const record = useRecordContext();
    return (
        <TagsManager record={record} isEditable={isEditable} />
    );
};

export const PrevNextButtonsWithHotkeys = <RecordType extends RaRecord = any>(
    props: PrevNextButtonProps<RecordType>
) => {
    const {
        hasPrev,
        hasNext,
        prevPath,
        nextPath,
    } = usePrevNextController<RecordType>(props);
    
    const navigate = useNavigate();

    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (document.activeElement !== document.body) {
                return;
            }

            if (event.key === 'ArrowLeft') {
                if (hasPrev && prevPath) {
                    event.preventDefault();
                    navigate(prevPath);
                }
            } else if (event.key === 'ArrowRight') {
                if (hasNext && nextPath) {
                    event.preventDefault();
                    navigate(nextPath);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hasPrev, hasNext, prevPath, nextPath, navigate]);

    return <PrevNextButtons {...props} />;
};


const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });

const conversationFilters = [
    <ReferenceArrayInput source="assistant_id" reference="assistants" alwaysOn>
        <AutocompleteArrayInput optionText="name" style={{'minWidth':'300px'}} filterToQuery={filterToQuery} />
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="assistant.customer_id" reference="customers" label="Customer">
        <AutocompleteArrayInput optionText="name" style={{'minWidth':'300px'}} filterToQuery={filterToQuery} label="Customer" />
    </ReferenceArrayInput>,

    <TextInput source="topic" />,
    <TextInput source="summary" />,
    <TextInput source="from_id" />,
    <SelectArrayInput source="tags" choices={tags} variant='outlined' />,    
    <SelectInput
    source="outcome"
    emptyText="resources.conversations.outcomes.all"
    choices={[        
        { id: 'completed', name: 'resources.conversations.outcomes.completed' },
        { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
        { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
        { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
        { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
        { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
    ]}
    alwaysOn
    />,        
    <DateInput source="date_from" label="Date From" />,
    <DateInput source="date_to" label="Date To" />
];

const Assistant = () => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return <div>{assistants[0].name}</div>;
};

// #todo: move to a separate file
export const TranslatedTextField = (props) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    // Haal de waarde dynamisch op met de 'source' prop
    const fieldValue = record ? record[props.source] : null;
    return fieldValue ? <span>{translate(props.translation_key+'.'+fieldValue)}</span> : <span>{props.emptyText}</span>;
}


const LocalCreatedAt = (props: any) => {
    const record = useRecordContext();
    const { data: assistants, isLoading, error } = useGetManyAggregate('assistants', { ids: [record.assistant_id] });
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return (
            <DateField
            source={props.source}
            options={{ year: 'numeric', month: 'numeric', day: 'numeric',hour: '2-digit', minute: '2-digit', timeZone: assistants[0].timezone }}
            transform={(value: string) => new Date(value+'+00:00')}        
            />
        )
};

const ListActions = () => (
    <TopToolbar>
        <Button
            component={Link}
            to="/conversations/notes"
            label="Notes"
        >
            <NotesIcon />
        </Button>
        <FilterButton />
    </TopToolbar>
);

export const ConversationList = () => {
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const translate = useTranslate();

    return (
        <List 
            exporter={false} 
            sort={{ field: 'created_at', order: 'DESC' }} 
            filters={conversationFilters} 
            actions={<ListActions />}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={<LocalCreatedAt source="created_at" label="resources.assistants.fields.created_at" />}
                    secondaryText={record => 
                        <>
                        <strong>{translate('resources.conversations.fields.outcome')}:</strong> {record.outcome ? translate('resources.conversations.outcomes.'+record.outcome) : <i>n/a</i>}
                        <br />
                        <strong>{translate('resources.conversations.fields.topic')}:</strong> {record.topic ? record.topic : <i>n/a</i>}
                        <br />
                        {['admin', 'partner'].includes(permissions) && <TagsManagerWrapper label='Tags' isEditable={false} />}
                        <br />
                        <strong>{translate('resources.conversations.fields.summary')}:</strong> {record.summary ? record.summary : <i>n/a</i>}

                        </>
                    }
                    tertiaryText={record => 
                        <div style={{ textAlign: 'right', fontSize: '0.875rem' }}>
                        {record.from_id ? record.from_id : <i>n/a</i>}
                        {record.from_name ? ' - ' + record.from_name : ''}
                        <br />
                        <Assistant />
                        </div>
                    }
                    linkType={record => record.canEdit ? "edit" : "show"} 
                    />
            ) : (
                <Datagrid rowClick="show" bulkActionButtons={false}
                sx={{
                    '& .column-summary': {
                        display: 'table-cell',
                        maxWidth: '25em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',    
                    },
                    '& .column-from_id': {
                        display: 'table-cell',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '8em',                                            
                    },

                }}
                >
                    <LocalCreatedAt source="created_at" label="resources.assistants.fields.created_at" />
                    <TextField source="from_id" sortable={false} emptyText='Unkown' label="resources.assistants.fields.from_id" />|
                    <DurationField source="duration" />
                    <TextField source="from_name" emptyText='Unknown'label="resources.assistants.fields.name" />
                    <TextField source="summary" emptyText='Not available' />
                    <TextField source="topic" emptyText='Not available' />
                    <TagsManagerWrapper/>
                    <TranslatedTextField source="outcome" emptyText='Not available' translation_key="resources.conversations.outcomes" />
                    <ReferenceField source="assistant_id" reference="assistants"><TextField source="name" /></ReferenceField>
                    {permissions === 'admin' && 
                        <FunctionField 
                            label="Rating" 
                            render={(record: any) => (JSON.parse(record.meta).rating) || ''} 
                        />
                    }                    
                </Datagrid>    
        )}            
        </List>
    );
}

const ConversationShowActions = () => {
    const { permissions } = usePermissions();
    const notify = useNotify();
    const record = useRecordContext();
    const translate = useTranslate();

    const handleCopy = (type: string) => {
        notify(translate(`resources.conversations.${type}_copied`), { type: 'info' });
    };

    const isAdmin = permissions === 'admin';

    // Check if record is loaded
    if (!record) {
        return null; // or return a loading indicator
    }

    return (
        <TopToolbar>
            {isAdmin && <PrevNextButtonsWithHotkeys linkType='show' />}
            <CopyToClipboard text={window.location.href} onCopy={() => handleCopy('url')}>
                <Button size="small" label="resources.conversations.copy_url">
                    <ContentCopyIcon />
                </Button>
            </CopyToClipboard>
            {isAdmin && record.messages && (
                <CopyToClipboard text={record.messages} onCopy={() => handleCopy('messages')}>
                    <Button size="small" label="resources.conversations.copy_messages">
                        <MessageIcon />
                    </Button>
                </CopyToClipboard>
            )}
            <ListButton />
            {isAdmin && <DeleteButton mutationMode="pessimistic" />}
        </TopToolbar>
    );
};


const AudioPlayer = () => {
    const translate = useTranslate();

    const { record } = useShowController();
    const apiUrl = import.meta.env.VITE_API_URL
    const auth = localStorage.getItem('auth');
    const parsedAuth = JSON.parse(auth || '{}');
    const url = apiUrl+"/conversations/"+record.id+"/recording.mp3?access_token="+parsedAuth.access_token;    
    return (
        <div><Typography component="span" variant="body2">{translate('resources.conversations.recording')}</Typography>
        <div>                   
            <audio controls>
                <source src={url ? url : ''} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>
        </div>
    );
};


export const ConversationShow = () => {
    const { permissions } = usePermissions();
    const translate = useTranslate();

    return (
        <Show title="resources.conversations.overview" actions={<ConversationShowActions />}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <SimpleShowLayout>                        
                        <ReferenceField source="assistant_id" reference="assistants"><TextField source="name" /></ReferenceField>                        
                        <TextField source="from_name" emptyText='Unkown' />
                        <TextField source="from_id" emptyText='Unkown' />
                        <DateField
                            source="created_at"                            
                            options={{ year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }} /* @todo: fix this workaround and implement TZ correctly */ 
                            locale="nl-NL"
                            transform={(value: string) => new Date(value+'+00:00')}
                        />                        
                        <AudioPlayer />
                        <ConversationsConversationNotesList/>

                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SimpleShowLayout>
                    <TextField source="summary" emptyText='Not available' />
                        <TextField source="topic" emptyText='Not available' />
                        <FunctionField
                            label="resources.conversations.fields.outcome"
                            render={(record: any) => translate('resources.conversations.outcomes.'+record.outcome)} 
                        />
                        {['admin', 'partner'].includes(permissions) && <TagsManagerWrapper label='Tags' isEditable={['admin', 'partner'].includes(permissions)} />}
                        {permissions === 'admin' && 
                            <FunctionField 
                                label="Rating" 
                                render={(record: any) => (JSON.parse(record.meta).rating) || 'N/A'} 
                            />
                        }   
                        <DurationField source="duration" />                    
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={12} md={12}>
                    <SimpleShowLayout>                        
                        <StructuredJsonField source="messages" />
                    </SimpleShowLayout>
                </Grid>
            </Grid>
        </Show>
    
    );
}

export const TwilioRedirect = () => {
    const { twilio_id } = useParams();
    const navigate = useNavigate();

    const { data, isLoading, error } = useGetOne(
        'conversations',
        { id: twilio_id },
        {
            onSuccess: (data) => {
                if (data?.id) {
                    navigate(`/conversations/${data.id}/show`, { replace: true });
                }
            },
            onError: () => {
                navigate('/conversations', { replace: true });
            }
        }
    );

    if (isLoading) return null;
};