// in src/i18nProvider.js
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import dutchMessages from 'ra-language-dutch';
import { email, I18N_CHANGE_LOCALE } from 'react-admin';

export const en_US = {
    ...englishMessages,
    app: {
        auth: {
            verify: "Verify",
            resend_code: "Resend Code",
            code_resent: "Verification code resent successfully",
            sign_in_to_your_account: 'Sign in to your account',
            email: 'Email',
            forgot_password: 'Forgot Password?',
            send_reset_instructions: 'Send Reset Instructions',
            back_to_login: 'Back to Login',
            if_account_exists: "If the email address you entered matches one in our records, " +
            "we've sent you instructions to reset your password.",            
            reset_password: 'Reset Password',
            new_password: 'New Password',
            confirm_password: 'Confirm Password',
            invalid_reset_token: 'Invalid reset token',
            passwords_do_not_match: 'Passwords do not match',
            password_reset_success: 'Password reset successfully',
            password_reset_failed: 'Password reset failed',
            password_reset_instructions_sent: 'Password reset instructions sent to your email',
            errors: {
                'INVALID_CREDENTIALS': 'Invalid email or password',
                'USER_NOT_FOUND': 'No account found with this email',
                'INVALID_RESET_TOKEN': 'Password reset link is invalid or has expired',
                'PASSWORD_TOO_WEAK': 'Password is too weak',
                'PASSWORDS_DO_NOT_MATCH': 'Passwords do not match',
                'SAME_PASSWORD': 'New password must be different from your current password',
                'EMAIL_EXISTS': 'An account with this email already exists',
                'GENERIC_ERROR': 'An error occurred. Please try again later.',
                'unknown': 'An error occurred. Please try again later.',
                'MAX_ATTEMPTS_REACHED': 'Maximum verification attempts reached. Please wait 10 minutes before requesting a new code.',
                'INVALID_CODE': 'The verification code you entered is incorrect. Please try again.',
                'VERIFICATION_FAILED': 'Verification failed. Please try again later.',
                'MAX_SEND_ATTEMPTS_REACHED': 'Too many code requests. Please wait 10 minutes before requesting a new code.',
                'CODE_SEND_FAILED': 'Failed to send verification code. Please try again later.',
                'INCORRECT_CREDENTIALS': 'Incorrect username or password',
            },
            verify_2fa: "Two-Factor Authentication",
            enter_verification_code: "Enter the verification code sent to your device",
            phone_number: 'Phone Number',
            incorrect_code: 'The verification code you entered is incorrect. Please try again.',
            check_mail: "Check your email",
        },
        validation: {
            required: 'Required',
            minLength: 'Password must be at least 8 characters',
            maxLength: 'Password must be less than 64 characters',
            password: {
                uppercase: 'Password must contain at least one uppercase letter',
                lowercase: 'Password must contain at least one lowercase letter',
                number: 'Password must contain at least one number',
                special: 'Password must contain at least one special character (!@#$%^&*)',
                weak: 'Password is too weak',
                same: 'New password must be different from old password',
                mismatch: 'Passwords do not match'
            },
            invalid_phone: 'Please enter a valid phone number',
            phone_too_short: 'Phone number must be at least 9 digits',
            phone_format: 'Enter phone number in E.164 format (e.g., +1234567890)',
            phone_number_required_for_2fa: 'Phone number is required for 2-factor authentication',
        },
        message: {
            password: {
                create: 'Create a strong password with at least 8 characters',
                reset: 'Enter your new password',
                change: 'Enter a new password different from your current one',
                confirmation: 'Please confirm your password'
            },
            invalid_form: 'The form is not valid. Please check your input'
        }
    },
    resources: {
        languages: { 
            nl_NL: "Dutch (Netherlands)",
            nl_BE: "Dutch (Belgium)",
            en_US: "English (United States)",
            es_ES: "Spanish (Spain)",
            de_DE: "German (Germany)",
            en_AU: "English (Australia)",
            en_UK: "English (United Kingdom)",
            el_GR: "Greek (Greece)",
            zh_CN: "Chinese (Mandarin, simplified)",
            fr_FR: "French (France)",
            fr_BE: "French (Belgium)",
            tr_TR: "Turkish (Turkey)",
            da_DK: "Danish (Denmark)",
            sv_SE: "Swedish (Sweden)",
            nb_NO: "Norwegian (Bokmål)",
            it_IT: "Italian (Italy)",
            pt_PT: "Portuguese (Portugal)",
            pl_PL: "Polish (Poland)",
            de_AT: "German (Austria)",
            de_CH: "German (Switzerland)",
        },        
        dashboard: {
            welcome_back: 'Welcome back',
            subheader: 'Here is a quick snapshot of Bonnie\'s performance.'
        },
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                name: 'Name',
                created_at: 'Date',
                users: 'User |||| Users',
                assistants: 'Assistant',
            }
        },
        generic: {
            save: 'Save',
            close: 'Close',
            copy: 'Copy',
            remove: 'Remove',
            save_and_close: 'Save & Close',
            settings: 'Settings',
            locale: 'Language',
            my_profile: 'My Profile',
            profile_updated: 'Your profile has been updated',
            unavailable: 'Not available',
            hide_filters: "Hide filters",
            show_filters: "Show filters",
            open_filters: "Open filters",
            filters: "Filters",
            clear_filters: "Clear filters",
            units: {
                hours: "hours",
                minutes: "minutes",
                calls: "calls"
            },
            date_shortcuts: {
                last_7_days: 'Last 7 days',
                last_30_days: 'Last 30 days',
                last_90_days: 'Last 90 days',
                this_year: 'This year',
                last_year: 'Last year',
                last_365_days: 'Last 365 days',
            },
            cancel: "Cancel",
            apply_filters: "Apply filters",
            showing_data: "Showing data for",
            over: "over",
            at: "at",
            all_assistants: "all assistants",
        },
        users: {
            name: 'User |||| Users',
            empty: 'No Users yet.',
            invite: 'Do you want to add one?',
            edit: 'Edit User',
            new_password: 'New Password',
            new_password_confirm: 'Confirm Password',
            fields: {
                first_name: 'First name',
                last_name: 'Last name',
                email: 'Email',
                role: 'Role',
                phone_number: 'Phone Number',
                requires_2fa: 'Enable 2-factor authentication'
            }, 
        },
        conversation_notes: {
            add_note: 'Add Note',
            edit_note: 'Edit Note',
            back_to_conversations: 'Back to Conversations',
            fields: {
                note: 'Note',
                content: 'Content',
                created_at: 'Date',
                created_by: 'Created by',
                is_private: 'Private',
                status: 'Status',
                assigned_to: 'Assigned to',
            },
            statuses: {
                open: 'Open',
                closed: 'Closed',
                none: 'None',
            },
        },             
        conversations: {
            recording: 'Recording',
            overview: 'Conversation Overview',
            role: 'Role',
            content: 'Content',
            tag_updated: 'Tag updated',
            add_tag: 'Add tag',
            fields: {
                summary: 'Summary',
                topic: 'Topic',
                result: 'Result',    
                outcome: 'Outcome',
                accessible_open_notes_count: 'Open notes',
                has_open_notes: 'Open notes',
            },
            results: {
                all: 'All',
                success: 'Success',
                failure: 'Failure',
                transferred: 'Transferred',
                none: 'None',
                null: 'None',
                followup: 'Follow-up',
            }, 
            outcomes: {
                all: 'All',
                none: 'None',
                completed: 'Completed',
                caller_follow_up: 'Caller Follow-Up',
                business_follow_up: 'Business Follow-Up',
                transferred: 'Transferred',
                information_provided: 'Information Provided',
                no_action: 'No Action',
            },
            'copy_url': 'Copy URL',
            'copy_messages': 'Copy Messages',
            'url_copied': 'URL to this conversation has been copied to clipboard',
            'messages_copied': 'Messages have been copied to clipboard',
            stats: {
                duration_title: 'Call Duration',
                count_title: 'Number of Calls',
                duration: 'Duration',
                count: 'Count',
                duration_label: 'Time',
                count_label: 'Number of Calls',
                direct_calls: 'Handled by Bonnie',
                transferred_calls: 'Transferred Calls',
                call_duration: 'Call Duration Bonnie',
                transfer_duration: 'Transfer Duration',
                total_calls: "Number of Calls",
                total_call_time: "Call Time",
                total_transfers: "Transfers",
                total_transfer_time: "Transfer time",
                transfer_count_percentage: "Transfer Rate",
                transfer_duration_percentage: "Transfer Time Rate",
                total_call_time_help: "The total call time of all conversations",
                total_calls_help: "The total number of conversations handled",
                transfer_count_percentage_help: "The percentage of conversations that were transferred to a human agent",
                transfer_duration_percentage_help: "The percentage of total conversation time spent on transferred conversations"
            },
        },
        "assistants": {
            "name": "Assistant |||| Assistants",
            "edit": "Edit Assistant",
            "banner_prompt_components_line1": "Our Assistant configuration has changed. New fields have been introduced to separate instructions into distinct categories, making it more structured and easier to manage.",
            "banner_prompt_components_note": "Note:",
            "banner_prompt_components_line2": "If you do not update these fields, everything will continue to work as it is. However, using these new options will simplify maintaining and updating your settings.",
            "fields": {
                "name": "Name",
                "from_id": "Caller ID",
                "prompt": "Call Handling Instructions",
                "post_call_prompt": "Post-Call Instructions",
                "post_call_prompt_help_text": "These instructions are used after the call has completed",
                "category": "Category",
                "default_language": "Greeting Language",
                "additional_languages": "Additional Supported Languages",
                "ai_model": "AI model",
                "config": "Custom configuraton",
                "timezone": "Business Timezone",
                "created_at": "Created At",
                "notification_outcomes": "Notification Triggers",
                "notification_user": "Recipient of Notifications",
                "config.notifications.include_transcript": "Include Call Transcript in Email",
                "whatsapp_image": "WhatsApp Profile Image",
                "greeting": "Greeting Message",
                "language": "Language",
                "lexicon": "Pronunciation",
                "edit_pronunciations": "Edit Pronunciations",
                "tasks": "Tasks",
                "deployment": "Deployment",
                "caller_id": "Linked phone number",
                "fallback_number": "Fallback Number",
                "customer_id": "Customer ID",
                "prompt_scenarios.transfer_policy": "Call Transfer Policy",
                "prompt_scenarios.call_policy": "Call Handling Policies & Guidelines",
                "prompt_scenarios.faq": "Frequently Asked Questions (FAQs)",
                "prompt_scenarios.context": "Context for the call",
                "prompt_scenarios.business_information": "Business Details",
                "prompt_scenarios.role": "Assistant's Role",          
                "config.stt.interruption": "Allow Interruption",
                "config.stt.backend": "Speech-to-Text Backend",
                "config.tools.formitable.config.api_key": "Formitable API Key",
                "config.tools.formitable.config.restaurant_id": "Formitable Restaurant ID",
                "config.tts.use_human_time_format": "Use Human Time Format",
                "phone_number_id": "Phone Number",
                "google_place_id": "Google Place ID",
            },
            "descriptions": {
                "name": "Enter the name of your business as it will be used by the assistant",
                "timezone": "Select the timezone your business operates in",
                "whatsapp_image": "Upload the image that will be used when sending WhatsApp messages",
                "greeting": "Enter the greeting message that will be used at the beginning of conversations.",
                "default_language": "Select the language in which the caller will be greeted",
                "additional_languages": "Select additional languages the user can switch to during the call. Limit this to the most common languages spoken by your customers to avoid the possibility of switching to an incorrect language",
                "lexicon": "Enter the pronunciation of words that the assistant may not pronounce correctly",
                "prompt": "Provide detailed instructions for the tasks that Bonnie should perform during the call",
                "notification_outcomes": "Select the outcomes that should trigger a notification to the specified user",
                "notification_user": "Specify the user who will receive all notifications",
                "deployment": "Details about the deployment configuration",
                "caller_id": "The phone number for this assistant",
                "fallback_number": "The fallback number to be used if the main number is unreachable",
                "customer_id": "The customer associated with this assistant",
                "config": "Custom configuration for the assistant",
                "config.notifications.include_transcript": "Choose whether to include the call transcript in the email notification",
                "config.stt.interruption": "Choose whether to allow the caller to interrupt the assistant while it is speaking",
                "config.stt.backend": "Select the backend to use for speech-to-text conversion",
                "config.tools.formitable.config.api_key": "Enter the API key for Formitable",
                "config.tools.formitable.config.restaurant_id": "Enter the restaurant ID for Formitable",
                "prompt_scenarios.transfer_policy": "Specify the conditions under which calls should be transferred and the destination for the transfer",
                "prompt_scenarios.call_policy": "Outline the general call handling policies and guidelines that Bonnie should follow during interactions with callers",
                "prompt_scenarios.faq": "Add common questions and their answers here to assist Bonnie in providing accurate information to callers",
                "prompt_scenarios.context": "Add context for the call here, e.g. date, time and caller number",
                "prompt_scenarios.business_information": "Provide key information about your business, including operating hours, address, website, and any other relevant details that Bonnie should know to assist during or after calls",
                "prompt_scenarios.role": "Describe the role and purpose of the assistant",                
                "config.tts.use_human_time_format": "This setting converts numeric time formats to a natural language format. For example, instead of '14:00', it will be pronounced as 'two o'clock'.",
                "phone_number_id": "Phone Number that is linked to this assistant",
                "google_place_id": "Select your business location",
            },
            try: {
                send: 'Send',
                type_your_message: 'Type your message',
                update: 'Update',
                edit_message: 'Edit Message',
            },
            errors: {
                invalid_phone: 'Invalid phone number',
            },
            general: 'General',
            call_flow: 'Call Flow',
            prompt: 'Prompt',
            notifications: 'Notifications',
            tabs: {
                "business_information": "About",
                "personality": "Personality",
                "tasks": "Instructions",
                "policies_and_context": "Policies & Context",
                "notifications": "Notifications",
                "tools": "Integrations",
                "admin": "Admin Settings", 
            },
            actions: {
                select_phone_number: 'Selecteer telefoonnummer',
            },
            phone_number: {
                confirm_title: 'Change Phone Number',
                confirm_content: 'Are you sure you wish to change the linked phone number?',
                no_options: "No available phone numbers found"
            },
        },
        'prompt-templates': {
            name: "Prompt Template |||| Prompt Templates"
        },
        "phone_numbers": {
            "name": "Phone Number |||| Phone Numbers",
            "fields": {
                "number": "Number",
                "assistant_id": "Assistant",
                "created_at": "Created At"
            }
        },
    },
};

export const en_AU = {
    ...en_US,
};

export const nl_NL = {
    ...dutchMessages,
    app: {
        auth: {
            verify: "Verifiëren",
            resend_code: "Code opnieuw versturen",
            code_resent: "Verificatiecode opnieuw verzonden",
            sign_in_to_your_account: 'Log in bij je account',
            email: 'Email',
            forgot_password: 'Wachtwoord vergeten?',
            send_reset_instructions: 'Verstuur reset instructies',
            back_to_login: 'Terug naar inloggen',
            if_account_exists: 'Als het e-mailadres dat u heeft ingevoerd overeenkomt met een adres in onze administratie, hebben wij u instructies gestuurd om uw wachtwoord opnieuw in te stellen.',
            reset_password: 'Wachtwoord resetten',
            new_password: 'Nieuw wachtwoord',
            confirm_password: 'Bevestig wachtwoord',
            invalid_reset_token: 'Ongeldige reset token',
            passwords_do_not_match: 'Wachtwoorden komen niet overeen',
            password_reset_success: 'Wachtwoord succesvol gereset',
            password_reset_failed: 'Wachtwoord resetten mislukt',
            password_reset_instructions_sent: 'Instructies voor het resetten van het wachtwoord zijn verzonden naar je e-mail',
            errors: {
                'INVALID_CREDENTIALS': 'Ongeldige email of wachtwoord',
                'USER_NOT_FOUND': 'Geen account gevonden met dit emailadres',
                'INVALID_RESET_TOKEN': 'De resetlink is ongeldig of verlopen',
                'PASSWORD_TOO_WEAK': 'Het wachtwoord is te zwak',
                'PASSWORDS_DO_NOT_MATCH': 'De wachtwoorden komen niet overeen',
                'SAME_PASSWORD': 'Nieuw wachtwoord moet verschillen van uw huidige wachtwoord',
                'EMAIL_EXISTS': 'Er bestaat al een account met dit emailadres',
                'GENERIC_ERROR': 'Er is een fout opgetreden. Probeer het later opnieuw.',
                'unknown': 'Er is een fout opgetreden. Probeer het later opnieuw.',
                'MAX_ATTEMPTS_REACHED': 'Maximaal aantal verificatiepogingen bereikt. Wacht 10 minuten voordat u een nieuwe code aanvraagt.',
                'INVALID_CODE': 'De verificatiecode die u heeft ingevoerd is onjuist. Probeer het opnieuw.',
                'VERIFICATION_FAILED': 'Verificatie mislukt. Probeer het later opnieuw.',
                'MAX_SEND_ATTEMPTS_REACHED': 'Te veel codeverzoeken. Wacht 10 minuten voordat u een nieuwe code aanvraagt.',
                'CODE_SEND_FAILED': 'Verificatiecode verzenden mislukt. Probeer het later opnieuw.',
                'INCORRECT_CREDENTIALS': 'Onjuiste gebruikersnaam of wachtwoord',
            },
            verify_2fa: "Twee-factor-authenticatie",
            enter_verification_code: "Voer de verificatiecode in die naar uw apparaat is verzonden",
            phone_number: "Telefoonnummer",
            incorrect_code: 'De verificatiecode die u heeft ingevoerd is onjuist. Probeer het opnieuw.',
            code_resent: "Verificatiecode opnieuw verzonden",
            check_mail: "Check uw e-mail",
        },
        validation: {
            required: 'Verplicht',
            minLength: 'Wachtwoord moet minimaal 8 tekens bevatten',
            maxLength: 'Wachtwoord mag maximaal 64 tekens bevatten',
            password: {
                uppercase: 'Wachtwoord moet minimaal één hoofdletter bevatten',
                lowercase: 'Wachtwoord moet minimaal één kleine letter bevatten',
                number: 'Wachtwoord moet minimaal één cijfer bevatten',
                special: 'Wachtwoord moet minimaal één speciaal teken bevatten (!@#$%^&*)',
                weak: 'Wachtwoord is te zwak',
                same: 'Nieuw wachtwoord moet verschillen van het oude wachtwoord',
                mismatch: 'Wachtwoorden komen niet overeen'
            },
            invalid_phone: 'Voer een geldig telefoonnummer in',
            phone_too_short: 'Telefoonnummer moet minimaal 9 tekens bevatten',
            phone_format: 'Voer het telefoonnummer in E.164-formaat in (bijv. +1234567890)',
            phone_number_required_for_2fa: 'Telefoonnummer is vereist voor 2-factor authenticatie',
        },
        message: {
            password: {
                create: 'Maak een sterk wachtwoord aan met minimaal 8 tekens',
                reset: 'Voer je nieuwe wachtwoord in',
                change: 'Voer een nieuw wachtwoord in dat verschilt van je huidige wachtwoord',
                confirmation: 'Bevestig je wachtwoord'
            },
            invalid_form: 'Het formulier is niet geldig. Controleer uw invoer'
        }
    },
    resources: {
        languages: { // Toevoegen van taal vertalingen onder de resources
            nl_NL: "Nederlands (Nederland)",
            nl_BE: "Nederlands (België)",
            en_US: "Engels (Verenigde Staten)",
            es_ES: "Spaans (Spanje)",
            de_DE: "Duits (Duitsland)",
            en_AU: "Engels (Australië)",
            en_UK: "Engels (Verenigd Koninkrijk)",
            el_GR: "Grieks (Griekenland)",
            zh_CN: "Chinees (Mandarijn, vereenvoudigd)",
            fr_FR: "Frans (Frankrijk)",
            fr_BE: "Frans (België)",
            tr_TR: "Turks (Turkije)",
            da_DK: "Deens (Denemarken)",
            sv_SE: "Zweeds (Zweden)",
            nb_NO: "Noors (Bokmål)",
            it_IT: "Italiaans (Italië)",
            pt_PT: "Portugees (Portugal)",
            pl_PL: "Pools (Polen)",
            de_AT: "Duits (Oostenrijk)",
            de_CH: "Duits (Zwitserland)",
        },
        dashboard: {
            welcome_back: 'Welkom terug',
            subheader: 'Hier een snel overzicht van Bonnie\'s prestaties.',
        },
        generic: {
            settings: 'Instellingen',  
            locale: 'Taal',
            remove: 'Verwijderen',
            my_profile: 'Mijn profiel',
            profile_updated: 'Uw profiel is bijgewerkt',
            unavailable: 'Niet beschikbaar',
            save: 'Opslaan',
            save_and_close: 'Opslaan & sluiten',
            close: 'Sluiten',
            copy: 'Kopiëren',
            hide_filters: "Verberg filters",
            show_filters: "Toon filters",
            open_filters: "Open filters",
            filters: "Filters",
            clear_filters: "Wis filters",
            units: {
                hours: "uur",
                minutes: "minuten",
                calls: "gesprekken"
            },
            date_shortcuts: {
                last_7_days: 'Laatste 7 dagen',
                last_30_days: 'Laatste 30 dagen',
                last_90_days: 'Laatste 90 dagen',
                this_year: 'Dit jaar',
                last_year: 'Afgelopen jaar',
                last_365_days: 'Laatste 365 dagen',
            },
            cancel: "Cancel",
            apply_filters: "Apply filters",
            showing_data: "Gegevens voor",
            over: "tijdens",
            at: "bij",
            all_assistants: "alle assistenten",
        },
        users: {
            name: 'Gebruiker |||| Gebruikers',
            empty: 'Nog geen gebruikers.',
            invite: 'Wil je er een toevoegen?',
            edit: 'Bewerk Gebuiker',
            new_password: 'Nieuw wachtwoord',
            new_password_confirm: 'Bevestig wachtwoord',
            fields: {
                first_name: 'Voornaam',
                last_name: 'Achternaam',
                locale: 'Taal',
                email: 'E-mail',
                role: 'Rol',
                customer_id: 'Klant',
                phone_number: 'Telefoonnummer',
                requires_2fa: 'Activeer 2fa-authenticatie',
            },
        },
        customers: {
            name: 'Klant |||| Klanten',
            fields: {
                name: 'Naam',
                created_at: 'Datum',
                users: 'Gebruiker |||| Gebruikers',
                assistants: 'Assistenten',
            }
        },
        "assistants": {
            "name": "Assistent |||| Assistenten",
            "edit": "Assistent bewerken",
            "banner_prompt_components_line1": "Onze assistentconfiguratie is gewijzigd. Er zijn nieuwe velden geïntroduceerd om instructies in afzonderlijke categorieën te scheiden, waardoor het meer gestructureerd en gemakkelijker te beheren is.",
            "banner_prompt_components_note": "Opmerking:",
            "banner_prompt_components_line2": "Als u deze velden niet bijwerkt, blijft alles werken zoals het is. Het gebruik van deze nieuwe opties zal echter het onderhouden en bijwerken van uw instellingen vereenvoudigen.",
            "fields": {
                "name": "Bedrijfsnaam",
                "customer_id": "Klant ID",
                "category": "Categorie",
                "default_language": "Begroetingstaal",
                "additional_languages": "Extra ondersteunde talen",
                "lexicon": "Uitspraak",
                "edit_pronunciations": "Uitspraak bewerken",
                "ai_model": "AI model",
                "from_id": "Beller ID",
                "prompt": "Instructies voor gespreksafhandeling",
                "post_call_prompt": "Instructies na het gesprek",
                "post_call_prompt_help_text": "Deze instructies worden gebruikt nadat het gesprek is afgerond",
                "notification_user": "Ontvanger van meldingen",
                "email": "E-mail",
                "greeting": "Begroetingsbericht",
                "formitable_id": "Formitable ID",
                "config": "Aangepaste configuratie",
                "timezone": "Bedrijfstijdzone",
                "conversations": "Gesprekken",
                "created_at": "Datum",
                "notification_outcomes": "Meldingsactiveringen",
                "config.notifications.include_transcript": "Gespreksverslag in e-mail opnemen",
                "whatsapp_image": "WhatsApp-profielafbeelding",
                "tasks": "Taken",
                "post_call_tasks": "Taken voor na het gesprek",
                "deployment": "Deployment",
                "caller_id": "Gekoppeld telefoonnummer",
                "fallback_number": "Fallback Nummer",
                "prompt_scenarios.transfer_policy": "Beleid voor doorverbinden",
                "prompt_scenarios.call_policy": "Beleid en richtlijnen voor gespreksafhandeling",
                "prompt_scenarios.faq": "Veelgestelde vragen (FAQs)",
                "prompt_scenarios.context": "Context voor gesprek",
                "prompt_scenarios.business_information": "Bedrijfsgegevens",
                "prompt_scenarios.role": "Rol van de assistent",            
                "config.stt.interruption": "Onderbreking toestaan",
                "config.stt.backend": "Speech-to-Text Backend",
                "config.tools.formitable.config.api_key": "Formitable API Key",
                "config.tools.formitable.config.restaurant_id": "Formitable Restaurant ID",    
                "config.tts.use_human_time_format": "Gebruik menselijke tijdnotatie",
                "phone_number_id": "Telefoonnummer",
                "google_place_id": "Google Place ID",
            },
            "descriptions": {
                "name": "Voer de naam van uw bedrijf in zoals deze door de assistent wordt gebruikt",
                "timezone": "Selecteer de tijdzone waarin uw bedrijf zich bevindt",
                "whatsapp_image": "Upload de afbeelding die wordt gebruikt bij het verzenden van WhatsApp-berichten",
                "greeting": "Voer het begroetingsbericht in dat aan het begin van gesprekken wordt gebruikt",
                "default_language": "Selecteer de taal waarin de beller wordt begroet",
                "additional_languages": "Selecteer extra talen die de gebruiker tijdens het gesprek kan kiezen. Beperk dit tot de meest gesproken talen door uw klanten om te voorkomen dat de gebruiker naar een verkeerde taal overschakelt",
                "lexicon": "Voer de uitspraak van woorden in die de assistent mogelijk niet correct uitspreekt",
                "prompt": "Geef gedetailleerde instructies voor de taken die Bonnie tijdens het gesprek moet uitvoeren",
                "notification_outcomes": "Selecteer de uitkomsten die een melding naar de opgegeven gebruiker moeten activeren",
                "notification_user": "Specificeer de gebruiker die alle meldingen ontvangt",
                "deployment": "Details over de deployment configuratie",
                "caller_id": "Het telefoonnummer dat de assistent gebruikt om te bellen",
                "fallback_number": "Het fallback-nummer dat moet worden gebruikt als het hoofdnummer onbereikbaar is",
                "customer_id": "De klant die aan deze assistent is gekoppeld",
                "config": "Aangepaste configuratie voor de assistent",
                "config.notifications.include_transcript": "Kies of u het gespreksverslag in de e-mailmelding wilt opnemen",
                "prompt_scenarios.transfer_policy": "Specificeer de voorwaarden waaronder gesprekken moeten worden doorgeschakeld en de bestemming voor de doorgeschakelde oproep",
                "prompt_scenarios.call_policy": "Geef het algemene beleid voor gespreksafhandeling en de richtlijnen die Bonnie moet volgen tijdens interacties met bellers",
                "prompt_scenarios.faq": "Voeg hier veelgestelde vragen en hun antwoorden toe om Bonnie te helpen nauwkeurige informatie aan bellers te verstrekken",
                "prompt_scenarios.context": "Voeg hier de context voor het gesprek toe, zoals datum, tijd en beller nummer",
                "prompt_scenarios.business_information": "Geef belangrijke informatie over uw bedrijf, zoals openingstijden, adres, website en andere relevante details die Bonnie moet weten om tijdens of na gesprekken te helpen",
                "prompt_scenarios.role": "Beschrijf de rol en het doel van de assistent",                
                "config.stt.interruption": "Kies of de beller de assistent mag onderbreken terwijl deze spreekt",
                "config.stt.backend": "Selecteer de backend die moet worden gebruikt voor spraak-naar-tekstconversie",
                "config.tools.formitable.config.api_key": "Voer de API-sleutel in voor Formitable",
                "config.tools.formitable.config.restaurant_id": "Voer het restaurant-ID in voor Formitable",                
                "config.tts.use_human_time_format": "Deze instelling schakelt de conversie van numerieke tijdsnotaties naar een natuurlijke taalformaat in. Bijvoorbeeld, in plaats van '14:00' wordt 'twee uur' uitgesproken.",
                "phone_number_id": "Telefoonnummer dat is gekoppeld aan deze assistent",
                "google_place_id": "Selecteer uw bedrijfslocatie",
            },
            tabs: {
                "business_information": "Over",
                "personality": "Persoonlijkheid",
                "tasks": "Instructies",
                "policies_and_context": "Beleid & Context",
                "notifications": "Notificaties",
                "tools": "Integraties",
                "admin": "Admin Instellingen", 
            },
            try: {
                send: 'Stuur',
                type_your_message: 'Typ uw bericht',
                update: 'Bijwerken',
                edit_message: 'Bericht bewerken',
            },
            general: 'Algemeen',
            call_flow: 'Gespreksstroom',
            prompt: 'Instructies',
            notifications: 'Notificaties',
            errors: {
                invalid_phone: 'Ongeldig telefoonnummer',
            },
            actions: {
                select_phone_number: 'Selecteer telefoonnummer',
            },
            phone_number: {
                confirm_title: 'Telefoonnummer wijzigen',
                confirm_content: 'Weet je zeker dat je het gekoppelde telefoonnummer wilt wijzigen?',
                no_options: "Geen beschikbare telefoonnummers gevonden"
            },
        },
        conversation_notes: {
            add_note: 'Notitie toevoegen',
            edit_note: 'Notitie bewerken',        
            back_to_conversations: 'Terug naar Gesprekken',
            fields: {
                note: 'Notitie',
                content: 'Inhoud',
                created_at: 'Datum',
                created_by: 'Gemaakt door',
                is_private: 'Privé',
                status: 'Status',
                assigned_to: 'Toegewezen aan',
            },
            statuses: {
                open: 'Open',
                closed: 'Gesloten',
                none: 'Geen',
            },
        },              
        conversations: {
            name: 'Gesprek |||| Gesprekken',
            overview: 'Gesprek Overzicht',
            recording: 'Opname',
            role: 'Rol',
            content: 'Inhoud',
            tag_updated: 'Tag bijgewerkt',
            add_tag: 'Tag toevoegen',
            fields: {
                assistant_id: 'Assistent',
                created_at: 'Datum',
                from_id: 'Van',
                from_name: 'Naam',
                destination: 'Bestemming',
                duration: 'Duur',
                summary: 'Samenvatting',
                topic: 'Onderwerp',
                result: 'Resultaat',
                outcome: 'Resultaat',
                messages: 'Berichten',
                accessible_open_notes_count: 'Open notities',
                has_open_notes: 'Open notities',
            },
            results: {
                all: 'Alle',
                success: 'Succesvol',
                failure: 'Fout',
                transferred: 'Doorgeschakeld',
                none: 'Geen',
                followup: 'Opvolging',
                null: 'Geen',
            },            
            outcomes: {
                all: 'Alle',
                none: 'Geen',
                completed: 'Afgerond',
                caller_follow_up: 'Opvolging Beller',
                business_follow_up: 'Opvolging Bedrijf',
                transferred: 'Doorverbonden',
                information_provided: 'Informatie Verstrekt',
                no_action: 'Geen Actie',
            },
            'copy_url': 'URL kopiëren',
            'copy_messages': 'Berichten kopiëren',            
            'url_copied': 'URL naar dit gesprek is gekopieerd naar het klembord',
            'messages_copied': 'Berichten zijn gekopieerd naar het klembord',
            stats: {
                duration_title: 'Gespreksduur',
                count_title: 'Aantal gesprekken',
                duration: 'Duur',
                count: 'Aantal',
                duration_label: 'Tijd',
                count_label: 'Aantal gesprekken',
                direct_calls: 'Afgehandeld door Bonnie',
                transferred_calls: 'Doorverbonden gesprekken',
                call_duration: 'Gespreksduur Bonnie',
                transfer_duration: 'Doorverbonden duur',
                total_calls: "Aantal Gesprekken",
                total_call_time: "Gesprekstijd",
                transfer_count_percentage: "Doorverbonden",
                transfer_duration_percentage: "Doorverbonden (tijd)",
                total_call_time_help: "De totale beltijd van alle gesprekken",
                total_calls_help: "Het totale aantal gesprekken dat er is afgehandeld",
                transfer_count_percentage_help: "Het percentage van gesprekken dat is doorverbonden naar een menselijke medewerker",
                transfer_duration_percentage_help: "Het percentage van de totale gesprekstijd dat is besteed aan doorverbonden gesprekken"
            },
        },
        'prompt-templates': {
            name: "Prompt Template |||| Prompt Templates"
        },
        "phone_numbers": {
            "name": "Telefoonnummer |||| Telefoonnummers",
            "fields": {
                "number": "Nummer",
                "assistant_id": "Assistent",
                "created_at": "Aangemaakt op"                
            }
        },
    },
};

const LOCALE_READABLE_NAMES = {
    "en-US": "English",
    "en-AU": "English (AU)",
    "nl-NL": "Nederlands",
    "fr-FR": "Français",
    "fr-BE": "Français (BE)",
};

export const getReadableLocaleName = (localeCode) => {
    const normalizedLocaleCode = localeCode.replace('_', '-');
    return LOCALE_READABLE_NAMES[normalizedLocaleCode] || "Unknown Locale";
};

const translations = { en_US, en_AU, nl_NL };

// Genereer de locale opties op basis van de keys in je translations object
const localeOptions = Object.keys(translations).map(locale => ({
    locale: locale,
    name: getReadableLocaleName(locale)
}));

export const i18nProvider = polyglotI18nProvider(
    locale => translations[locale],
    'en_US', // default locale
    localeOptions,
);
