import React, { useEffect, useRef, useState, useCallback } from 'react';
import { TextField, Box, Avatar, Typography, IconButton, CircularProgress } from '@mui/material';
import { useInput, useRecordContext, useDataProvider, useTranslate } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

interface GooglePlacesAutocompleteProps {
    source: string;
    label?: string;
}

interface PlaceDetails {
    name: string;
    photoUrl?: string;
    city?: string;
    address?: string;
}

interface Prediction {
    place_id: string;
    description: string;
    structured_formatting?: {
        main_text: string;
        secondary_text: string;
    };
}

const GooglePlacesAutocomplete = (props: GooglePlacesAutocompleteProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [placeDetails, setPlaceDetails] = useState<PlaceDetails>({ name: '' });
    const [predictions, setPredictions] = useState<Prediction[]>([]);
    const [error, setError] = useState<string | null>(null);
    const {
        field,
        fieldState: { error: fieldError },
    } = useInput(props);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);

    // Add this near the top of the component
    const inputId = `google-places-${props.source}`;

    // Load place details when component mounts or record changes
    useEffect(() => {
        const loadPlaceDetails = async () => {
            if (!record?.config?.google_place_id) return;

            const placeId = record.config.google_place_id;
            setIsLoading(true);

            try {
                const { data: place } = await dataProvider.custom(`places/details/${placeId}`, {
                    method: 'GET',
                });
                                
                setPlaceDetails({
                    name: place.name || '',
                    photoUrl: place.photoUrl || null,
                    city: place.city || '',
                    address: place.address || ''
                });
                setError(null);
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
                console.error('Error fetching place details:', error);
                setError(errorMessage);
            } finally {
                setIsLoading(false);
            }
        };

        loadPlaceDetails();
    }, [record, dataProvider]);

    // Remove the old useEffect for input handling and replace with this function
    const handleInputChange = useCallback(
        debounce(async (value: string) => {
            if (!value) {
                setPredictions([]);
                return;
            }

            setIsLoading(true);
            try {
                const { data } = await dataProvider.custom(
                    `places/autocomplete?input=${encodeURIComponent(value)}&types=establishment`, 
                    {
                        method: 'GET',
                    }
                );
                
                setPredictions(data.predictions);
                setError(null);
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
                console.error('Error fetching predictions:', error);
                setPredictions([]);
                setError(errorMessage);
            } finally {
                setIsLoading(false);
            }
        }, 300),
        [dataProvider]
    );

    const handlePredictionSelect = async (prediction: Prediction) => {
        setIsLoading(true);
        try {
            const { data: place } = await dataProvider.custom(`places/details/${prediction.place_id}`, {
                method: 'GET',
            });
            
            const details = {
                name: place.name || '',
                photoUrl: place.photoUrl || null,
                city: place.city || '',
                address: place.address || ''
            };

            field.onChange(prediction.place_id);
            setPlaceDetails(details);
            setPredictions([]);
            setError(null);
            
            if (inputRef.current) {
                inputRef.current.value = details.name;
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
            console.error('Error fetching place details:', error);
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClear = () => {
        setPlaceDetails({ name: '' });
        setInputValue('');
        field.onChange('');
        setPredictions([]);
        setError(null);
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    // Debounce utility function
    function debounce(func: Function, wait: number) {
        let timeout: NodeJS.Timeout;
        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    // Add this new handler for keyboard navigation
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!predictions.length) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setSelectedIndex(prev => (prev < predictions.length - 1 ? prev + 1 : prev));
                break;
            case 'ArrowUp':
                e.preventDefault();
                setSelectedIndex(prev => (prev > 0 ? prev - 1 : prev));
                break;
            case 'Enter':
                e.preventDefault();
                if (selectedIndex >= 0) {
                    handlePredictionSelect(predictions[selectedIndex]);
                }
                break;
            case 'Escape':
                setPredictions([]);
                setSelectedIndex(-1);
                break;
        }
    };

    // Reset selected index when predictions change
    useEffect(() => {
        setSelectedIndex(-1);
    }, [predictions]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {placeDetails.name ? (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 2,
                    padding: 2,
                    borderRadius: 1,
                    position: 'relative'
                }}>
                    {placeDetails.photoUrl ? (
                        <Avatar
                            src={placeDetails.photoUrl}
                            alt={placeDetails.name}
                            sx={{ 
                                width: 40, 
                                height: 40,
                                border: '1px solid #eee',
                                flexShrink: 0
                            }}
                        />
                    ) : (
                        <Avatar
                            sx={{ 
                                width: 40, 
                                height: 40,
                                bgcolor: 'primary.main',
                                flexShrink: 0
                            }}
                        >
                            {placeDetails.name.charAt(0)}
                        </Avatar>
                    )}
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        justifyContent: 'center',
                        minHeight: 40,
                        flex: 1
                    }}>
                        <Typography variant="subtitle1" sx={{ lineHeight: 1.2 }}>
                            {placeDetails.name}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {placeDetails.address && (
                                <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
                                    {placeDetails.address}
                                </Typography>
                            )}
                            {placeDetails.city && (
                                <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
                                    {placeDetails.city}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <IconButton 
                            onClick={handleClear}
                            size="small"
                            sx={{ 
                                fontSize: '0.875rem',
                                gap: 0.5,
                                color: 'text.secondary',
                                padding: '4px 8px',
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    color: 'text.primary'
                                }
                            }}
                        >
                            <Typography variant="body2" component="span">
                                {translate('resources.generic.remove')}
                            </Typography>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            ) : (
                <>
                    <TextField
                        inputRef={inputRef}
                        value={inputValue}
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            handleInputChange(e.target.value);
                        }}
                        fullWidth
                        error={!!fieldError || !!error}
                        helperText={error || fieldError?.message}
                        variant="outlined"
                        size="small"
                        placeholder="Search for your business..."
                        id={inputId}
                        label={props.label || "Business Location"}
                        InputProps={{
                            endAdornment: isLoading && (
                                <CircularProgress size={20} />
                            ),
                        }}
                        onKeyDown={handleKeyDown}
                    />

                    {predictions.length > 0 && (
                        <Box sx={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            right: 0,
                            zIndex: 1000,
                            backgroundColor: 'background.paper',
                            boxShadow: 3,
                            borderRadius: 1,
                            mt: 1,
                            maxHeight: '300px',
                            overflowY: 'auto'
                        }}>
                            {predictions.map((prediction, index) => (
                                <Box
                                    key={prediction.place_id}
                                    onClick={() => handlePredictionSelect(prediction)}
                                    sx={{
                                        p: 1.5,
                                        cursor: 'pointer',
                                        backgroundColor: selectedIndex === index ? 'action.selected' : 'inherit',
                                        '&:hover': {
                                            backgroundColor: 'action.hover'
                                        }
                                    }}
                                >
                                    <Typography variant="body2">
                                        {prediction.structured_formatting ? (
                                            <>
                                                <strong>{prediction.structured_formatting.main_text}</strong>
                                                <br />
                                                <span style={{ color: 'text.secondary' }}>
                                                    {prediction.structured_formatting.secondary_text}
                                                </span>
                                            </>
                                        ) : (
                                            prediction.description
                                        )}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default GooglePlacesAutocomplete;