import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Form,
    useTranslate,
    useNotify,
    useDataProvider,
} from 'react-admin';
import {
    Button,
    Card,
    CircularProgress,
    Typography,
    Box,
} from '@mui/material';
import Logo from './layout/Logo';

interface TwoFactorAuthProps {
    onCancel?: () => void;
}

const TwoFactorAuth = ({ onCancel }: TwoFactorAuthProps) => {
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState('      ');
    const translate = useTranslate();
    const notify = useNotify();
    const location = useLocation();
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        const twoFaCompleted = localStorage.getItem('2faCompleted');
        if (!auth) {
            navigate('/login');
        }
        if (auth && twoFaCompleted) {
            navigate('/');
        }

    }, [navigate]);

    React.useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown]);

    const handleTwoFASubmit = async () => {
        const code = verificationCode.trim();

        if (!isCodeComplete()) {
            notify('app.auth.errors.INVALID_CODE', { type: 'error' });
            return;
        }

        setLoading(true);
        try {
            const response = await dataProvider.custom('verify-2fa', {
                method: 'POST',
                postdata: { code }
            });

            const { data } = response;
            localStorage.setItem('auth', JSON.stringify(data));
            localStorage.setItem('2faCompleted', 'true');
            navigate(location.state?.nextPathname || '/');

        } catch (error: any) {
            setLoading(false);
            const errorCode = error?.response?.data?.detail || 'VERIFICATION_FAILED';
            const errorMessage = error?.response?.data?.message || `app.auth.errors.${errorCode}`;
            notify(errorMessage, { type: 'error' });
            setVerificationCode('      ');
        }
    };

    const handleTwoFAInputChange = (index: number, value: string) => {
        if (!/^\d?$/.test(value)) return;

        const newCode = verificationCode.split('');
        newCode[index] = value || ' ';
        setVerificationCode(newCode.join(''));

        if (value && index < 5) {
            const nextInput = document.querySelector<HTMLInputElement>(`input[name="code-${index + 1}"]`);
            nextInput?.focus();
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
        if (e.key === 'Backspace') {
            if (!verificationCode[index].trim()) {
                e.preventDefault();
                if (index > 0) {
                    const prevInput = document.querySelector<HTMLInputElement>(`input[name="code-${index - 1}"]`);
                    prevInput?.focus();
                }
            }
        }
    };

    const handleResendCode = async () => {
        setResendLoading(true);

        try {
            const response = await dataProvider.custom('resend-2fa-code', {
                method: 'POST'
            });
            const { data } = response;
            notify('app.auth.code_resent', { type: 'success' });
            setCountdown(30);
        } catch (error: any) {
            if (error?.response?.status === 401) {
                notify('app.auth.errors.UNAUTHORIZED', { type: 'error' });
                localStorage.removeItem('auth');
                localStorage.removeItem('2faCompleted');
                navigate('/login');
            } else {
                const errorCode = error?.response?.data?.detail || 'CODE_SEND_FAILED';
                const errorMessage = error?.response?.data?.message || `app.auth.errors.${errorCode}`;
                notify(errorMessage, { type: 'error' });
            }
            throw error;
        } finally {
            setResendLoading(false);
        }
    };

    // Add helper function to check if code is complete
    const isCodeComplete = () => {
        return verificationCode.trim().length === 6;
    };

    return (
        <Form onSubmit={handleTwoFASubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F7F2',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: 'translateY(-15%)',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: '2em',
                            transform: 'scale(0.75)',
                        }}
                    >
                        <Logo />
                    </Box>
                    <Card sx={{ minWidth: 400, padding: '3em' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                marginBottom: '1em',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: '#172121',
                                fontSize: '1.1rem',
                            }}
                        >
                            {translate('app.auth.verify_2fa')}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                marginBottom: '1em',
                                textAlign: 'center',
                                color: '#172121',
                            }}
                        >
                            {translate('app.auth.enter_verification_code')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                justifyContent: 'center',
                                marginBottom: '2em',
                            }}
                        >
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <input
                                    key={index}
                                    name={`code-${index}`}
                                    type="tel"
                                    maxLength={1}
                                    value={verificationCode[index].trim()}
                                    onChange={(e) => handleTwoFAInputChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    disabled={loading}
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        fontSize: '1.2rem',
                                        textAlign: 'center',
                                        backgroundColor: '#fff',
                                        outline: 'none',
                                    }}
                                    autoFocus={index === 0}
                                />
                            ))}
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading || !isCodeComplete()}
                                fullWidth
                                sx={{ mt: 2, mb: 2 }}
                            >
                                {loading ? (
                                    <CircularProgress size={25} thickness={2} />
                                ) : (
                                    translate('app.auth.verify')
                                )}
                            </Button>
                            <Button
                                onClick={handleResendCode}
                                disabled={resendLoading || countdown > 0}
                                fullWidth
                                sx={{
                                    color: '#44937D',
                                    textDecoration: 'none',
                                    textAlign: 'left',
                                    mb: 2,
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {resendLoading ? (
                                    <CircularProgress size={20} thickness={2} />
                                ) : countdown > 0 ? (
                                    `${translate('app.auth.resend_code')} (${countdown}s)`
                                ) : (
                                    translate('app.auth.resend_code')
                                )}
                            </Button>
                            {onCancel && (
                                <Button
                                    onClick={onCancel}
                                    fullWidth
                                    sx={{
                                        color: '#44937D',
                                        textDecoration: 'none',
                                        textAlign: 'center',
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    {translate('ra.action.cancel')}
                                </Button>
                            )}
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Form>
    );
};

export default TwoFactorAuth;