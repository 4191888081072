import React, { useEffect, useState } from 'react';
import { Confirm, AutocompleteInput, useRecordContext, BooleanInput, TabbedForm, TabbedFormTabs, CheckboxGroupInput, AutocompleteArrayInput, useTranslate, usePermissions, SelectInput, Create, Edit, ReferenceField, ChipField, Datagrid, List, TextField, TextInput, useDataProvider, useGetOne, ReferenceInput, FormDataConsumer, ImageField, ImageInput, required } from 'react-admin';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { Stack, Chip, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomMDXEditor from './CustomMdxEditor';
import { TabbedFormWithRevision, FieldDiff, SmartFieldDiff } from '@react-admin/ra-history';
import LexiconEditDialog from './assistant/LexiconEditDialog';
import { PlayAudioButton } from './assistant/PlayAudioButton';


import Typography from '@mui/material/Typography';
import CustomCloneButton from './CustomCloneButton';

import {
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';

import TryAssistantButton from './TryAssistantButton';
import { Box, Grid } from '@mui/material';
import { FieldHeader } from './FieldHeader';
import CallAssistantButton from './CallAssistantButton';
import VoiceCall from './VoiceCall';

import { useFormContext } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { TextField as MuiTextField } from '@mui/material';
import GooglePlacesAutocomplete from './assistant/GooglePlacesAutocomplete';

interface Assistant {
    id: number | null;
    name: string;
}

export const AssistantDiff = () => (
    <Stack gap={2}>
        <FieldDiff source="name" />
        <FieldDiff source="prompt_scenarios.about_business" />

        {/* Personality Tab */}
        <FieldDiff source="prompt_scenarios.role" />
        <FieldDiff source="greeting" />

        {/* Tasks Tab */}
        <SmartFieldDiff source="prompt" />
        <SmartFieldDiff source="post_call_prompt" />

        {/* Policies and Context Tab */}
        <SmartFieldDiff source="prompt_scenarios.faq" />
        <SmartFieldDiff source="prompt_scenarios.transfer_policy" />
        <SmartFieldDiff source="prompt_scenarios.call_policy" />

    </Stack>
);

const CustomToolbar = ({isEdit = false}) => {
    const { permissions } = usePermissions();
    const record = useRecordContext();
    return (
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
                {permissions !== 'demo' && <SaveButton label="resources.generic.save_and_close"/>}
                {permissions !== 'demo' && isEdit && <SaveButton 
                label="resources.generic.save"
                mutationOptions={{
                    onSuccess: () => {
                    }}
                }
                type="button"
                variant="text"
                />}
            </Box>
            <Box sx={{ display: 'flex', gap: '16px' }}> {/* Voeg hier de gap eigenschap toe */}
                {['admin', 'partner'].includes(permissions) && isEdit && <CustomCloneButton excludeFields={['id', 'notification_user', 'caller_id', 'fallback_number', 'stripe_subscription']} variant="text" resource="assistants" record={record} />}
                {['admin', 'partner'].includes(permissions) && <DeleteButton mutationMode="pessimistic" />}
            </Box>
        </Toolbar>
    );
}

const validatePhone = (value: string) => {
    // RegEx voor E.164 formaat
    const phoneRegex = /^\+[1-9]\d{1,14}$/;

    // Lege waarde is toegestaan
    if (!value) {
        return undefined;
    }

    // Test of de waarde voldoet aan het E.164 formaat
    if (!phoneRegex.test(value)) {
        // Retourneer een foutmelding als het niet voldoet
        return 'resources.assistants.errors.invalid_phone';
    }

    // Als alles in orde is, retourneer undefined (geen fout)
    return undefined;
};

const postFilters = [
    <TextInput source="name" alwaysOn />,
];


const LanguageInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/languages', { method: 'GET' });
                const formattedChoices = data.map((language: any) => {
                    // Vervang koppelteken door underscore
                    const languageKey = language.replace('-', '_');
                    // Gebruik de aangepaste taalcode om de vertaling op te halen
                    const translatedName = translate('resources.languages.'+languageKey);
                    return { id: language, name: translatedName };
                });
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching languages:', error);
            }
        };

        fetchLanguages();
    }, [dataProvider, translate]);

    return (
        <>
                <FieldHeader title="resources.assistants.fields.default_language" description="resources.assistants.descriptions.default_language" />
                <AutocompleteInput
                    source="default_language"
                    label=" "
                    choices={choices}
                    fullWidth
                    isRequired
                />

                <FieldHeader title="resources.assistants.fields.additional_languages" description="resources.assistants.descriptions.additional_languages" />
                        <AutocompleteArrayInput
                            source="additional_languages"
                            label=" "
                            choices={choices}
                            fullWidth
                        />
    </>
    );
};

const TimezoneInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const translate = useTranslate();

    useEffect(() => {
        const fetchTimezones = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/timezones', { method: 'GET' });
                const formattedChoices = data.map((timezone: string) => ({ id: timezone, name: timezone }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching timezones:', error);
            }
        };

        fetchTimezones();
    }, [dataProvider]);

    return <AutocompleteInput source="timezone" choices={choices} label='resources.assistants.fields.timezone' fullWidth isRequired />;
};

const DeploymentInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const translate = useTranslate();
    const record = useRecordContext();

    const optionRenderer = (choice: any) => {
        if (!choice) return '';
        return (
            <span>
                <span><Chip label={choice.vendor} size="small" color='default' /> </span>            
                <Typography component="span" variant="body2">{(choice.friendly_name)?choice.friendly_name:choice.model}</Typography>
            </span>
        )
    }

    useEffect(() => {
        const fetchAiModels = async () => {
            setLoading(true);
            try {
                const { data } = await dataProvider.custom(
                    'assistants/aideployments' + (record?.id ? `?assistant_id=${record.id}` : ''), 
                    { method: 'GET' }
                );
                const formattedChoices = data.map((deployment: any) => ({
                    id: deployment.id,
                    vendor: deployment.vendor,
                    model: deployment.model,
                    friendly_name: deployment.friendly_name
                }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching deployments:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAiModels();
    }, [dataProvider, record]);

    if (loading) {
        return null; // Of een loading indicator
    }

    return (
        <SelectInput
            source="ai_deployment_id"
            choices={choices}
            optionText={optionRenderer}
            label={translate('resources.assistants.fields.ai_model')}
            fullWidth
            isRequired
            defaultValue=""
            disabled={loading}
        />
    );
};
  
const CallAssistantModal = ({ open, onClose, assistant }: {open: boolean, onClose: (event: React.MouseEvent<HTMLButtonElement>) => void, assistant: Assistant | null}) => {
    const modalStyle = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,
      zIndex: 1301
    };
  
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="call-assistant-modal"
        aria-describedby="modal-to-call-assistant"
      >
        <Box sx={modalStyle}>
            <Box sx={
                {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }
            }>  
                <Chip label= {assistant?.name} color='primary' />
                <IconButton onClick={onClose} color='error'>
                    <CloseIcon />
                </IconButton>
            </Box>
          {assistant?.id && <VoiceCall assistantId={assistant.id} />}
        </Box>
      </Modal>
    );
  };


  const CallButtonWithRecord = ({ onClick: onClick }: { onClick: (event: React.MouseEvent<HTMLButtonElement>, assistant: Assistant) => void }) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
      <CallAssistantButton 
        onClick={(event) => onClick(event, { id: record.id, name: record.name })}
      />
    );
  };

export const AssistantList = () => {
    const { permissions } = usePermissions();
    const [callModalOpen, setCallModalOpen] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState<Assistant>({ id: null, name: '' });

    const handleCallClick = (event: React.MouseEvent<HTMLButtonElement>, assistant: Assistant) => {
        event.stopPropagation();
        setSelectedAssistant(assistant);
        setCallModalOpen(true);
      };
    
    const handleCloseCallModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setCallModalOpen(false);
        setSelectedAssistant({ id: null, name: '' });
    };

    return (
        <>
            <List filters={postFilters} hasCreate={['admin', 'partner'].includes(permissions)} exporter={false} resource='assistants'>
                <Datagrid rowClick="edit" bulkActionButtons={false}>`
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceField reference="users" source="notification_user">
                        <ChipField source="first_name" />
                    </ReferenceField>
                    <ReferenceField source="phone_number_id" reference="phone-numbers">
                        <TextField source="number" />
                    </ReferenceField>
                    <ReferenceField source="customer_id" reference="customers"><TextField source="name" /></ReferenceField>        
                    <TryAssistantButton />
                    <CallButtonWithRecord onClick={handleCallClick} />
                </Datagrid>
            </List>
            <CallAssistantModal
                open={callModalOpen}
                onClose={(event) => handleCloseCallModal(event)}
                assistant={selectedAssistant}
            />
        </>
        );
}

  const UserName = ({ id }) => {
    const { data: user } = useGetOne('users', { id });
    if (!user) return 'Unknown';
    return (
        <>
            {user.first_name} {user.last_name}
        </>
    );
};  
  

interface CommonFormProps {
    isEdit: boolean;
    defaultValues?: any; // Je kunt hier een specifieker type definiëren afhankelijk van de structuur van je defaultValues.
    customToolbar?: React.ReactNode;
  }

 
const CommonForm: React.FC<CommonFormProps> = ({ isEdit, defaultValues }) =>  {
    const { permissions } = usePermissions(); 
    const record = useRecordContext();
    const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });
    const isAdminOrPartner = ['admin', 'partner'].includes(permissions);
    
    return (
        <>
            <TabbedFormWithRevision 
                warnWhenUnsavedChanges 
                defaultValues={defaultValues} 
                toolbar={<CustomToolbar isEdit={isEdit} />} 
                diff={<AssistantDiff />} 
                skipUserDetails={true} 
                allowRevert={true}
                renderName={id => <UserName id={id} />}
                tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
            >
                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.business_information">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8} alignItems="top">
                            <FieldHeader title="resources.assistants.fields.name" description="resources.assistants.descriptions.name" /> 
                            <TextInput source="name" fullWidth label="" />
                            
                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.business_information" description="resources.assistants.descriptions.prompt_scenarios.business_information" />
                            <TextInput source="prompt_scenarios.about_business" fullWidth multiline className="custom-multiline" label="" />     

                            { ['admin', 'partner'].includes(permissions) &&
                            <>
                            <FieldHeader title="resources.assistants.fields.customer_id" description="resources.assistants.descriptions.customer_id" />
                            <ReferenceInput source="customer_id" reference="customers">
                                <AutocompleteInput optionText="name" fullWidth filterToQuery={(searchText: any) => ({ name: `%${searchText}%` })} validate={required()} />
                            </ReferenceInput>
                            </>
                            }
                            <FieldHeader title="resources.assistants.fields.phone_number_id" description="resources.assistants.descriptions.phone_number_id" />
                            <PhoneNumberInput />

                            <FieldHeader title="resources.assistants.fields.fallback_number" description="resources.assistants.descriptions.fallback_number" />
                            <FormDataConsumer>
                                {({ formData }) => (
                                    isAdminOrPartner ? (
                                        <TextInput source="fallback_number" fullWidth validate={validatePhone} label=""/>
                                    ) : (
                                        <MuiTextField
                                            value={formData.fallback_number || ''}
                                            disabled
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            label=""
                                        />
                                    )
                                )}
                            </FormDataConsumer>

                            <FieldHeader title="resources.assistants.fields.timezone" description="resources.assistants.descriptions.timezone" />
                            <TimezoneInput />     
                            <FieldHeader title="resources.assistants.fields.google_place_id" description="resources.assistants.descriptions.google_place_id" />
                            <GooglePlacesAutocomplete source="config.google_place_id" label="" />
                            <Box sx={{ mb: 2 }} />

                            <FieldHeader title="resources.assistants.fields.whatsapp_image" description="resources.assistants.descriptions.whatsapp_image" />
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {                                        
                                        return (
                                            <ImageInput 
                                                source="config.whatsapp_image" 
                                                label=" "
                                                options={{disabled:formData.config && formData.config.whatsapp_image && formData.config.whatsapp_image.src}}
                                                placeholder={formData.config && formData.config.whatsapp_image && formData.config.whatsapp_image.src ? <></> : null}
                                            >
                                                <ImageField source="src" title="title" sx={{ '& .RaImageField-image': { minWidth: '400px', maxWidth: '500px',  height: 'auto' } }}/>
                                            </ImageInput>
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>                                                                        
                        </Grid>
                    </Grid>
                </TabbedFormWithRevision.Tab>

                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.personality">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8}>

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.role" description="resources.assistants.descriptions.prompt_scenarios.role" />                                       
                            <TextInput source="prompt_scenarios.role" fullWidth multiline className="custom-multiline" label="" />


                            <Grid container spacing={1} alignItems="top">
                                <Grid item xs={11}>
                                    <FieldHeader title="resources.assistants.fields.greeting" description="resources.assistants.descriptions.greeting" />
                                </Grid>
                                <Grid item xs={1}>
                                    <FormDataConsumer>
                                    {({ formData, ...rest }) => (
                                            <PlayAudioButton
                                                endpoint="/assistants/play/audio" 
                                                params={{
                                                    text: formData.greeting,
                                                    language: formData.default_language,
                                                    id: formData.id
                                                }}
                                            />
                                        )}
                                        </FormDataConsumer>    
                                    </Grid>
                                    <TextInput source="greeting" fullWidth multiline className="custom-multiline" label="" />
                                </Grid>    

                            <LanguageInput />  
                            <LexiconEditDialog />

                        </Grid>
                    </Grid>        

                    </TabbedFormWithRevision.Tab>


                    <TabbedFormWithRevision.Tab label="resources.assistants.tabs.tasks">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item xs={12} md={8}>
                                <FieldHeader title="resources.assistants.fields.prompt" description="resources.assistants.descriptions.prompt" />
                                <CustomMDXEditor source="prompt" />

                                <FieldHeader title="resources.assistants.fields.post_call_prompt" description="resources.assistants.fields.post_call_prompt_help_text" />
                                <CustomMDXEditor source="post_call_prompt" />
                            </Grid>
                        </Grid>
                    </TabbedFormWithRevision.Tab>

                    <TabbedFormWithRevision.Tab label="resources.assistants.tabs.policies_and_context">
                        <Grid container spacing={1} alignItems="top">
                            <Grid item xs={12} md={8}>
                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.faq" description="resources.assistants.descriptions.prompt_scenarios.faq" />
                                <CustomMDXEditor source="prompt_scenarios.faq" />

                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.transfer_policy" description="resources.assistants.descriptions.prompt_scenarios.transfer_policy" />                                
                                <CustomMDXEditor source="prompt_scenarios.transfer_policy" />


                                <FieldHeader title="resources.assistants.fields.prompt_scenarios.call_policy" description="resources.assistants.descriptions.prompt_scenarios.call_policy" />
                                <CustomMDXEditor source="prompt_scenarios.call_policy" />

                            </Grid>
                        </Grid>
                    </TabbedFormWithRevision.Tab>

                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.notifications">
                    <Grid container spacing={2}> 
                        <Grid item xs={12} md={8}> 
                            <FieldHeader title="resources.assistants.fields.notification_outcomes" description="resources.assistants.descriptions.notification_outcomes" />
                            <CheckboxGroupInput
                                        defaultValue={[]}
                                        label=""
                                        source="notification_outcomes"                            
                                        choices={[        
                                            { id: 'business_follow_up', name: 'resources.conversations.outcomes.business_follow_up' },
                                            { id: 'caller_follow_up', name: 'resources.conversations.outcomes.caller_follow_up' },
                                            { id: 'transferred', name: 'resources.conversations.outcomes.transferred' },
                                            { id: 'information_provided', name: 'resources.conversations.outcomes.information_provided' },
                                            { id: 'completed', name: 'resources.conversations.outcomes.completed' },
                                            { id: 'no_action', name: 'resources.conversations.outcomes.no_action' },
                                        ]}
                                        row={false}
                                        format={value => Array.isArray(value) ? value : []} 
                                        /> 

                                    <FieldHeader title="resources.assistants.fields.notification_user" description="resources.assistants.descriptions.notification_user" />                                        
                                    <ReferenceInput source="notification_user" reference="users">
                                        <AutocompleteInput 
                                            fullWidth 
                                            optionText={(choice) => `${choice.first_name} ${choice.last_name} (${choice.email})`} 
                                            filterToQuery={(searchText: any) => ({ 
                                                email: `%${searchText}%`, 
                                                //first_name: `%${searchText}%`, #todo allow search for OR
                                                //last_name: `%${searchText}%` 
                                            })} 
                                        />                                            
                                    </ReferenceInput>                        

                                    <FieldHeader 
                                        title="resources.assistants.fields.config.notifications.include_transcript" 
                                        description="resources.assistants.descriptions.config.notifications.include_transcript" 
                                    />
                                    <BooleanInput 
                                        source="config.notifications.include_transcript" 
                                        label=" "
                                    />

                        </Grid> 
                        </Grid>
                </TabbedFormWithRevision.Tab>

                {record && record.config && record.config.tools && record.config.tools.formitable &&
                <TabbedFormWithRevision.Tab label="resources.assistants.tabs.tools">
                    <Grid container spacing={1} alignItems="top">
                        <Grid item xs={12} md={8}>
                            <FieldHeader title="resources.assistants.fields.config.tools.formitable.config.restaurant_id" description="resources.assistants.descriptions.config.tools.formitable.config.restaurant_id" />
                            <TextInput source="config.tools.formitable.config.restaurant_id" fullWidth />

                            <FieldHeader title="resources.assistants.fields.config.tools.formitable.config.api_key" description="resources.assistants.descriptions.config.tools.formitable.config.api_key" />
                            <TextInput source="config.tools.formitable.config.api_key" fullWidth />
                        </Grid>
                    </Grid>
                </TabbedFormWithRevision.Tab>
                }

                { ['admin', 'partner'].includes(permissions) &&
                <TabbedForm.Tab label="resources.assistants.tabs.admin">
                    <Grid container spacing={2}> 
                        <Grid item xs={12} md={8}> 

                            <FieldHeader title="resources.assistants.fields.deployment" description="resources.assistants.descriptions.deployment" />
                            <DeploymentInput />

                            <FieldHeader title="resources.assistants.fields.prompt_scenarios.context" description="resources.assistants.descriptions.prompt_scenarios.context" />
                            <CustomMDXEditor source="prompt_scenarios.context" />
                            <FieldHeader 
                            title="resources.assistants.fields.config.tts.use_human_time_format" 
                            description="resources.assistants.descriptions.config.tts.use_human_time_format" 
                            />
                            <BooleanInput 
                                source="config.tts.use_human_time_format" 
                                label=" "
                            />

                            <FieldHeader 
                                title="resources.assistants.fields.config.stt.interruption" 
                                description="resources.assistants.descriptions.config.stt.interruption" 
                            />
                            <BooleanInput 
                                source="config.stt.interruption" 
                                label=" "
                            />

                            <FieldHeader title="resources.assistants.fields.config.stt.backend" description="resources.assistants.descriptions.config.stt.backend" />
                            <SelectInput
                                source="config.stt.backend"
                                choices={[
                                    { id: 'azure', name: 'Microsoft Azure' },
                                    { id: 'deepgram', name: 'Deepgram' },
                                ]}
                                label=""
                                emptyText="Default"
                                defaultValue="azure"
                            />                    

                            <FieldHeader title="resources.assistants.fields.config" description="resources.assistants.descriptions.config" />
                            <CustomJsonInput />    
                        </Grid>
                    </Grid>
                </TabbedForm.Tab>
                }
                
            </TabbedFormWithRevision>
            </>
    );
  }
// Aangepaste titel component voor AssistantEdit
const AssistantTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return record ? (
        <>{translate('resources.assistants.edit')}: {record.name}</>
    ) : null;
};



export const AssistantEdit = () => {
    return (
        <Edit title={<AssistantTitle />}  mutationMode='pessimistic' resource='assistants'>
            <CommonForm isEdit={true} />
        </Edit>
    );
}


export const AssistantCreate = () => {
    const [defaultValues, setDefaultValues] = useState<any>(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const fetchDefaultValues = async () => {
            try {
                const { data } = await dataProvider.getDefaults('assistants');
                setDefaultValues(data);
            } catch (error) {
                console.error('Error fetching default values', error);
            }
        };

        if (defaultValues === null) {
            fetchDefaultValues();
        }
    }, [dataProvider, defaultValues]);

    if (defaultValues === null) {
        return null;
    }

    return (
        <Create resource='assistants' redirect="list">
            <CommonForm isEdit={false} defaultValues={defaultValues} />
        </Create>
    );
}

const CustomJsonInput = () => {
    const { setValue, trigger } = useFormContext();

    return (
        <FormDataConsumer>
            {({ formData }) => (
                <JsonView 
                    src={formData?.config || {}}
                    theme="github"
                    enableClipboard={true}
                    displaySize={true}
                    editable={{
                        edit: true,
                        add: true,
                        delete: true
                    }}
                    collapsed={false}
                    style={{ 
                        backgroundColor: 'transparent',
                        padding: '8px'
                    }}
                    onChange={({ src }) => {
                        // Update het config object
                        setValue('config', src, {
                            shouldDirty: true,    // Markeer het formulier als gewijzigd
                            shouldTouch: true     // Markeer het veld als aangeraakt
                        });
                        
                        // Trigger validatie om de save button te updaten
                        trigger('config');
                    }}
                />
            )}
        </FormDataConsumer>
    );
};

const PhoneNumberInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const record = useRecordContext();
    const { setValue } = useFormContext();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const isAdminOrPartner = ['admin', 'partner'].includes(permissions);
    
    useEffect(() => {
        const fetchPhoneNumbers = async () => {
            try {
                const { data } = await dataProvider.custom('assistants/phone-numbers', 
                    { 
                        method: 'POST', 
                        postdata: { assistant_id: record?.id } 
                    } 
                );
                const formattedChoices = data.map(number => ({ id: number.id, name: number.number }));
                setChoices(formattedChoices);
            } catch (error) {
                console.error('Error fetching numbers:', error);
            }
        };

        fetchPhoneNumbers();
    }, [dataProvider, record]);

    const handleEditClick = () => {
        setShowConfirm(true);
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        setIsEditing(true);
    };

    const handleClose = () => {
        setShowConfirm(false);
    };

    return (
        <Box sx={{ mb: 2 }}>
            <FormDataConsumer>
                {({ formData }) => {
                    const selectedNumber = choices.find(choice => choice.id === formData.phone_number_id);

                    if (!isAdminOrPartner || (selectedNumber && !isEditing)) {
                        return (
                            <>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <MuiTextField
                                        value={selectedNumber?.name ?? null}
                                        disabled
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label=""
                                    />
                                    {isAdminOrPartner && selectedNumber && (
                                        <IconButton 
                                            onClick={handleEditClick}
                                            size="small"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </Box>
                                <Confirm
                                    isOpen={showConfirm}
                                    title={translate('resources.assistants.phone_number.confirm_title')}
                                    content={translate('resources.assistants.phone_number.confirm_content')}
                                    onConfirm={handleConfirm}
                                    onClose={handleClose}
                                />
                            </>
                        );
                    }

                    return (
                        <AutocompleteInput
                            source="phone_number_id"
                            choices={choices}
                            fullWidth
                            noOptionsText={translate('resources.assistants.phone_number.no_options')}
                            label=""
                            onChange={(value) => {
                                setValue('phone_number_id', value || null);
                                setIsEditing(false);
                            }}
                        />
                    );
                }}
            </FormDataConsumer>
        </Box>
    );
};
